import { onScreen } from 'Lib/on-screen/on-screen';
import { Popup } from 'Lib/popup/popup';
import { eventSender } from 'Lib/event-sender/event-sender';

const $newYearAnimation = $('.new-year-animation');
const cNewYearAnimationActive = 'new-year-animation_active';

$(window).on('scroll', $.throttle(1000, () => {
    onScreen($('.wrapper__new-year-news .js-new-year-animation-btn, .bg_js-new-year-animation .books'), () => {
        $newYearAnimation.addClass(cNewYearAnimationActive);
    });

    $newYearAnimation.removeClass(cNewYearAnimationActive);
}));

document.querySelectorAll('.new-year-animation')?.forEach((item) => {
    const popup = new Popup('popup', item.querySelector('.advent__popup'));

    item.addEventListener('click', (e) => {
        console.log(e.target);

        if (e.target.classList.contains('new-year-animation__item-slide')) {
            popup.open();

            eventSender.customDL({
                event: 'eksmo',
                eventAction: 'click',
                eventCategory: 'Главная страница',
                eventLabel: 'Главная страница - кнопка пасхалка',
            });
        }
    });
});